<template>
    <vx-card :title="'View Canvass Item Requested (' + this.status + ')'">
        <div class="vx-row mb-6">
            <vs-button 
                class="ml-4 mt-2" 
                color="danger" 
                icon-pack="feather" 
                icon="icon-arrow-left"
                @click="handleBack()"
            >Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <h4>Selection</h4>
                <hr>
                <br>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Canvas Request Code</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Canvas Handover Code</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="handoverCode ? handoverCode : 'Not yet'" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Territory</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="selectedTerritory ? selectedTerritory.label : ''" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="selectedWarehouse ? selectedWarehouse.label : ''" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Sales</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="selectedSales ? selectedSales.label : ''" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Loading Date</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="loadingDate" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="w-full pr-6 md:basis-1/2">
                    <h4>Approval Status</h4>
                    <hr>
                    <br>
                    <div class="vx-row mb-6">
                        <table class="m-3 vs-table vs-table--tbody-table table-bordered" width="100%">
                            <thead class="vs-table--thead">
                                <tr>
                                    <th>Approver</th>
                                    <th>Status</th>
                                    <th>Inbox Time</th>
                                    <th>Action Time</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(tr, index) in approvalReason" :key="index" style="padding-bottom: 5px">
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.approver }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.status }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.inbox_time }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.action_time }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.reason }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <h4>Requested Items</h4>
                <hr>
                <br>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="50%">SKU Description</th>
                            <th width="10%">Qty</th>
                            <th width="20%">HU</th>
                            <th width="10%">Qty UOM</th>
                            <th width="10%">Total Qty (UOM)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(skuLine, index) in skuLines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="skuLine.label" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.qty" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="skuLine.unit" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.qty_uom" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="(skuLine.qty * skuLine.qty_uom)" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6" v-if="handoverLines.length > 0">
            <div class="vx-col w-full">
                <h4>Handover Items</h4>
                <hr>
                <br>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="35%">SKU Description</th>
                            <th width="7%">Qty</th>
                            <th width="10%">HU</th>
                            <th width="7%">Qty UOM</th>
                            <th width="11%">Total Qty (UOM)</th>
                            <th width="20%">Batch / Exp. Date</th>
                            <th width="5%">Warehouse Handover</th>
                            <th width="5%">Sales Handover</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(skuLine, index) in handoverLines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="skuLine.label" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.qty" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="skuLine.unit" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.qty_uom" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="(skuLine.qty * skuLine.qty_uom)" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="skuLine.batch" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-checkbox v-model="skuLine.warehouse_handover" disabled></vs-checkbox>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-checkbox v-model="skuLine.sales_handover" disabled></vs-checkbox>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";

export default {
    components: {},
    data() {
        return {
            id: null,
            readonly: true,
            status: "Loading...",
            code: null,
            handoverCode: null,
            selectedWarehouse: null,
            selectedTerritory: null,
            selectedSales: null,
            loadingDate: null,
            reason: "",
            approvalReason: [
                {
                    approver: null,
                    status: null,
                    inbox_time: null,
                    action_time: null,
                    reason: null,
                }
            ],
            skuLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    label: null,
                    unit: null,
                    qty: 0,
                    qty_uom: 0,
                }
            ],
            handoverLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    label: null,
                    unit: null,
                    qty: 0,
                    qty_uom: 0,
                    batch: null,
                    warehouse_handover: false,
                    sales_handover: false,
                }
            ]
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http.get("/api/v1/canvas/detail/" + this.id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.status = resp.data.status
                    this.code = resp.data.request_code
                    this.handoverCode = resp.data.handover_code
                    this.selectedTerritory = resp.data.territories
                    this.selectedWarehouse = resp.data.warehouses
                    this.selectedSales = resp.data.sales
                    this.loadingDate = resp.data.loading_date
                    this.approvalReason = resp.data.approver_reason
                    this.skuLines = resp.data.sku_lines
                    this.handoverLines = resp.data.handover_lines
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error)
            })
        },
        handleBack() {
            let tabOrder
            switch (this.status) {
                case "Waiting":
                    tabOrder = 1;
                    break;
                case "Confirmed":
                    tabOrder = 3;
                    break;
                case "Rejected":
                    tabOrder = 4;
                    break;
                case "Pending":
                    tabOrder = 5;
                    break;
                case "Handover":
                    tabOrder = 6;
                    break;
                case "Sales Handover":
                    tabOrder = 6;
                    break;
                case "Complete":
                    tabOrder = 6;
                    break;
                case "Canceled":
                    tabOrder = 7;
                    break;
                default:
                    break;
            }

            this.$router.push({
                name: "canvas.item-request",
                params: {status: tabOrder}
            });
        }
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
    },
}
</script>